import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ArrowRightOutlined } from '@ant-design/icons'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import DropHeader from '../shared/DropHeader'
import { colors } from '../../styles/variables'
import { media, getScreenType } from '../../styles/util'
import { H6, MonoP } from '../shared/text'

import OpeningQuote from '../../assets/img/icons/opening-quote.svg'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.navy};
  position: relative;
  ${media.mobile`
    padding: 0 25px 80px 25px;
  `}
  ${media.tablet`
    padding: 0 40px 80px 40px;
  `}
  ${media.desktop`
    padding: 0 30px 150px 30px;
  `}
`

const Grid = styled.div`
  margin: 0 auto;
  width: 100%;
  display: grid;
  align-items: center;
  ${media.mobile`
    padding-top: 50px;
  `}
  ${media.tablet`
    grid-template-columns: 275px 1fr;
    grid-column-gap: 50px;
    padding-top: 40px;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1.5fr;
    max-width: 1200px;
    padding-top: 60px;
  `}
`

const AvatarGrid = styled(Grid)`
  ${media.mobile`
    padding-top: 0;
  `}
  ${media.tablet`
    padding-top: 0;
  `}
  ${media.desktop`
    padding-top: 0;
  `}
`

const HeadshotWrapper = styled.div`
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: inline;
    height: 300px;
  `}
  ${media.desktop`
    height: 375px;
  `}
`

const QuoteContainer = styled.div`
  height: fit-content;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Quote = styled(H6)`
  color: ${colors.lightText};
  padding-bottom: 23px;
  display: flex;
  ${media.mobile`
    text-align: center;
    border-bottom: 1px solid ${colors.lightText};
    height: fit-content;
    min-height: 250px;
    align-items: center;
  `}
  ${media.tablet`
    align-items: flex-start;
    text-align: left;
    border: none;
    height: fit-content;
    &:before {
      content: '“';
      position: absolute;
      margin-left: -16px;
    }
  `}
`

const Author = styled(MonoP)`
  color: ${colors.lightText};
  ${media.mobile`
    text-align: center;
    padding-top: 23px;
  `}
  ${media.tablet`
    text-align: left;
    padding-top: 0;
  `}
`

const AvatarRow = styled.div`
  display: grid;
  align-items: center;
  ${media.mobile`
    grid-template-columns: repeat(3, 65px);
    grid-column-gap: 24px;
    justify-content: center;
    margin-top: 10px;
  `}
  ${media.tablet`
    grid-template-columns: repeat(3, 65px) 1fr;
    grid-column-gap: 18px;
    justify-items: start;
    margin-top: 0;
  `}
`

const AvatarWrapper = styled.div`
  height: 64px;
  width: 64px;
  cursor: pointer;
  filter: ${(props: { active?: boolean }) => (!props.active ? 'grayscale(100%)' : 'none')};
  transition: 250ms transform, filter ease-in-out;
  &:hover {
    ${media.desktop`
      transform: scale(1.08);
    `}
    filter: grayscale(0%);
  }
  ${media.mobile`
    transform: ${(props: { active?: boolean }) => (props.active ? 'scale(1.3)' : 'scale(1)')};
  `}
  ${media.tablet`
    transform: ${(props: { active?: boolean }) => (props.active ? 'scale(1.15)' : 'scale(1)')};
  `}
`

const ArrowWrapper = styled(AvatarWrapper)`
  align-items: center;
  margin-left: 10px;
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: flex;
  `}
`

const ArrowIcon = styled(ArrowRightOutlined)`
  color: ${colors.lightText};
  font-size: 17px;
  cursor: pointer;
  transition: 250ms transform, color ease-in-out;
  &:hover {
    transform: scale(1.2);
    color: ${colors.orange};
  }
`

const QuoteMark = styled.img`
  width: 75px;
  margin: 0 auto 20px auto;

  ${media.tablet`
    display: none;
  `}
`

const query = graphql`
  query {
    billand: file(relativePath: { regex: "/vets/farren-billand.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 375)
      }
    }
    nelson: file(relativePath: { regex: "/vets/kerri-nelson.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 375)
      }
    }
    robinson: file(relativePath: { regex: "/vets/laura-robinson.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 375)
      }
    }
  }
`

interface ProductReviewProps {
  quote: string
  author: string
  imageKey: string
}

const VetEndorsement = ({ productReview }: { productReview?: ProductReviewProps }) => {
  const images = useStaticQuery(query)
  const [activeVet, setActiveVet] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.to('.animatedGrid', { duration: 1, ease: Power3.easeOut, opacity: 1 })
  }, [])

  let reviews = [
    {
      quote:
        'Finn has evolved with the times. So many products are outdated these days and Finn has a great, fresh approach to the pet supplement world.',
      author: 'Dr. Laura Robinson, DVM',
      img: getImage(images.robinson)
    },
    {
      quote:
        'Finn prioritizes the best quality ingredients and makes it easy for pet owners to find supplements that will benefit their companion animals.',
      author: 'Dr. Kerri Nelson, DVM, PGRS-C',
      img: getImage(images.nelson)
    },
    {
      quote: "Our pets' best interest was truly in mind during the creation of these supplements.",
      author: 'Dr. Farren Billand, DVM',
      img: getImage(images.billand)
    }
  ]

  if (productReview) {
    reviews = [
      {
        ...productReview,
        img: getImage(images[productReview.imageKey])
      }
    ]
  }

  const handleArrowClick = () => {
    const reviewCount = reviews.length
    if (activeVet === reviewCount - 1) setActiveVet(0)
    else setActiveVet((prevState) => prevState + 1)
  }

  const handleVetChange = (isArrow: boolean, idx?: number) => {
    if (!isAnimating) {
      setIsAnimating(true)
      gsap.fromTo(
        '.animatedGrid',
        { opacity: 1, x: 0 },
        { duration: 0.5, ease: Power3.easeOut, x: -300, opacity: 0 }
      )
      setTimeout(() => {
        if (isArrow) handleArrowClick()
        else if (idx) setActiveVet(idx)
      }, 500)
      gsap.fromTo(
        '.animatedGrid',
        { opacity: 0, x: 0 },
        { duration: 0.5, delay: 0.5, ease: Power3.easeIn, x: 0, opacity: 1 }
      )
      setTimeout(() => setIsAnimating(false), 600)
    }
  }

  const isMobile = getScreenType() === 'mobile'

  return (
    <Section>
      <DropHeader title={`Loved by Pets, \nEndorsed by Vets`} color={colors.lightText} />
      <Grid className="animatedGrid" style={{ opacity: 0 }}>
        <HeadshotWrapper>
          <GatsbyImage
            image={reviews[activeVet].img}
            alt={reviews[activeVet].author}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </HeadshotWrapper>
        <QuoteContainer>
          <QuoteMark src={OpeningQuote} aria-hidden />
          <Quote>{isMobile ? reviews[activeVet].quote : `${reviews[activeVet].quote}”`}</Quote>
          <Author>{reviews[activeVet].author}</Author>
        </QuoteContainer>
      </Grid>
      {reviews.length > 1 && (
        <AvatarGrid>
          <br />
          <AvatarRow>
            {reviews.map((reviewer, idx) => (
              <AvatarWrapper
                key={reviewer.author}
                active={activeVet === idx}
                onClick={() => handleVetChange(false, idx)}
              >
                <GatsbyImage
                  image={reviewer.img}
                  alt={`Learn more about ${reviews[activeVet].author}`}
                />
              </AvatarWrapper>
            ))}
            <ArrowWrapper onClick={() => handleVetChange(true)}>
              <ArrowIcon />
            </ArrowWrapper>
          </AvatarRow>
        </AvatarGrid>
      )}
    </Section>
  )
}

export default VetEndorsement
