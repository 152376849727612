import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import DropHeader from '../shared/DropHeader'
import FiveStars from '../shared/FiveStars'
import { P, MonoP } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
`

const Grid = styled.div`
  display: grid;
  ${media.mobile`
    grid-template-columns: 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr;
    margin-top: -40px;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  opacity: 0;
  ${media.mobile`
    height: 290px;
  `}
  ${media.desktop`
    height: 100%;
    width: 100%;
  `}
`

const ReviewsGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 363px;
  grid-template-rows: auto;
  justify-content: center;
  align-content: center;
  ${media.mobile`
    grid-template-columns: 1fr;
    padding: 0 30px 47px 30px;
  `}
  ${media.desktop`
    grid-template-columns: 500px;
    grid-row-gap: 35px;
    padding: 60px 40px 40px 40px;
  `}
`

const ReviewGroup = styled.div`
  opacity: 0;
  ${media.mobile`
    padding: 30px 0;
    border-top: 0.5px solid ${colors.lightText};
  `}
  ${media.desktop`
    border: none;
    padding: 0;
  `}
`

const StarsRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 22px;
  text-transform: uppercase;
  color: ${colors.slate};
  margin: 0 0 12px 0;
`

const StyledP = styled(P)`
  &:before {
    content: '“';
    position: absolute;
    margin-left: -9px;
  }
`

const query = graphql`
  query {
    image: file(relativePath: { regex: "/homepage/worth-barking.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`

const Reviews = () => {
  const images = useStaticQuery(query)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const from = { opacity: 0, x: 20 }
    const sharedConfig = {
      scrollTrigger: '.reviews',
      ease: Power3.easeOut,
      opacity: 1,
      duration: 1.5,
      x: 0
    }
    gsap.fromTo('.imageAction', { opacity: 0 }, { ...sharedConfig, delay: 0.35, duration: 0.75 })
    gsap.fromTo('.review0', from, { ...sharedConfig, delay: 0.6 })
    gsap.fromTo('.review1', from, { ...sharedConfig, delay: 0.8 })
    gsap.fromTo('.review2', from, { ...sharedConfig, delay: 1.0 })
    gsap.fromTo('.review3', from, { ...sharedConfig, delay: 1.2 })
    // gsap.fromTo('.allStar', { opacity: 0, y: -20 }, { ...sharedConfig, delay: 0.75, y: 0, duration: 3 })
  }, [])

  const reviews = [
    {
      author: 'Desiree B.',
      quote:
        'We’ve been giving Ruthy the Hip & Joint supplement and she’s running up and down the stairs again! She’s 8 years old and I’ve never seen her this active!'
    },
    {
      author: 'Dev R.',
      quote:
        'Duke always gets scared and jumpy whenever he knows we’re going in the car, but Finn’s Calming Aid chews have definitely helped to chill him out and we can finally get on the road again with ease! Finally!!'
    },
    {
      author: 'Lindsey W.',
      quote:
        'Knox and I take our supplements every day together! I give him Multivitamin and Skin & Coat, and use them as treats as we try to train him at home.'
    },
    {
      author: 'Caleb P.',
      quote:
        'I’ve been looking for chews to give to Nala without any nasty fillers or bad ingredients, and I’m so happy I found Finn! They show up at our doorstep every month, and she gobbles them down!'
    }
  ]

  return (
    <Section>
      <DropHeader title="Worth Barking About" />
      <Grid className="reviews">
        <ImageWrapper className="imageAction">
          <GatsbyImage
            image={getImage(images.image)}
            alt="Man and woman with dog"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          />
        </ImageWrapper>
        <ReviewsGrid>
          {reviews.map((review, idx) => (
            <ReviewGroup key={review.author} className={`review${idx}`}>
              <StarsRow>
                <FiveStars color={colors.orange} fontSize={24} />
                <MonoP>{review.author}</MonoP>
              </StarsRow>
              <StyledP>{review.quote}”</StyledP>
            </ReviewGroup>
          ))}
        </ReviewsGrid>
      </Grid>
    </Section>
  )
}

export default Reviews
