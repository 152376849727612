import React from 'react'

import Jumbotron from 'src/components/Homepage/Jumbotron'
import Certified from 'src/components/Homepage/Certified'
import BeTheHero from 'src/components/Homepage/BeTheHero'
import GetStarted from 'src/components/Homepage/GetStarted'
import TheFinnWay from 'src/components/Homepage/TheFinnWay'
import Reviews from 'src/components/Homepage/Reviews'
import VetEndorsement from 'src/components/shared/VetEndorsement'
import PressBanner from 'src/components/shared/PressBanner'
import { Singles, Bundles } from 'src/components/OurProducts'

const Homepage = () => (
  <>
    <Jumbotron />
    <PressBanner bg="lightBlue" color="navy" homepage ticking />
    <Singles title="Shop Supplements" buttonCopy="Shop Now" isHomePage sustain />
    <Certified/>
    <GetStarted />
    <Bundles title="Save on Bundles" buttonCopy="Shop Now" isHomePage />
    <TheFinnWay />
    <VetEndorsement />
    <Reviews />
  </>
)

export default Homepage
