import React from 'react'
import styled from 'styled-components'
import { StarFilled } from '@ant-design/icons'

const Container = styled.div`
  color: ${props => props.color};
  font-size: ${(props: { fontSize: number }) => props.fontSize}px;
  padding-bottom: 3px;
`

const SpacedStar = styled(StarFilled)`
  margin-right: 5px;
  &:last-of-type {
    margin: 0;
  }
`

interface FiveStarProps {
  color: string
  fontSize: number
}

const FiveStars: React.FC<FiveStarProps> = ({ color, fontSize }) => {
  return (
    <Container color={color} fontSize={fontSize}>
      <SpacedStar />
      <SpacedStar />
      <SpacedStar />
      <SpacedStar />
      <SpacedStar />
    </Container>
  )
}

export default FiveStars
