import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { LargeP, H1, P } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'
import { SecondaryButton } from 'src/components/shared/buttons'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Repurpose from 'src/assets/img/homepage/repurpose.svg'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
`

const Grid = styled.div`
  width: 100%;
  margin: 0;

  display: grid;
  align-items: start;
  height: 100%;

  padding: 0;

  ${media.mobile`
  grid-template-columns: 1fr;
  `}

  ${media.tablet`
    justify-items: start;
    grid-template-columns: 1fr;

  `}
  ${media.desktop`
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
  `}
`

const TextContainer = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;

  display: grid;
  align-items: center;
  grid-row-gap: 37px;

  ${media.mobile`
    text-align: center;
    justify-items: center;
    padding: 50px 25px 80px;
  `}

  ${media.desktop`
    max-width: 100%;
    grid-row-gap: 20px;
    padding: 50px 80px 100px;
    text-align: left;
    justify-items: left;
  `}
`

const StyledH1 = styled(H1)`
  color: ${colors.navy};

  ${media.mobile`
    font-size: 55px;
    line-height: 60px;
  `}
  ${media.tablet`
    font-size: 60px;
    line-height: 70px;
  `}
  ${media.desktop`
    font-size: 85px;
    line-height: 100px;
  `}
`

const LifestyleImg = styled.div`
  width: 100%;
  height: 100%;
`

const ButtonGrid = styled.div`
  position: relative;

  display: grid;
  place-items: center;
  grid-gap: 15px;

  ${media.tablet`
    grid-gap: 17px;
  `}
  ${media.desktop`
    grid-template-columns: auto auto;
    margin-top: 19px;
  `}

  & button {
    min-width: 233px;
    min-height: 67px;
    color: ${colors.navy};
    border: 1px solid ${colors.navy};
    svg {
      fill: ${colors.navy};
    }
    & p {
      font-size: 26px;
    }
  }
`
const SubText = styled(P)`
  color: ${colors.navy};
  font-size: 24px;
`

const query = graphql`
  query {
    tin: file(relativePath: { regex: "/homepage/green-tin.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
  }
`

const Certified = () => {
  const { tin } = useStaticQuery(query)

  const handleClick = (destination: string) => {
    navigate(destination)
  }

  return (
    <Section>
      <Grid>
      <TextContainer>
          <StyledH1>
          We’re Certified<br/>Plastic-Negative
          </StyledH1>
          <img src={Repurpose} />
          <SubText>Discover how we're striving to make the world a better place for future pups through our sustainability initiatives.</SubText>
          <ButtonGrid>
            <SecondaryButton
              copy="Learn more"
              arrow
              color="sustain"
              onClick={() => handleClick('/plasticnegative')}
            />
          </ButtonGrid>
        </TextContainer>
        <LifestyleImg>
          <GatsbyImage
            image={getImage(tin)}
            alt="Woman with dog"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
          />
        </LifestyleImg>
      </Grid>
    </Section>
  )
}

export default Certified
