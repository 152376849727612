import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Homepage from 'src/components/Homepage'
import SEO from 'src/components/shared/SEO'

const SEO_CONFIG = {
  title: 'Best Natural Dog Vitamins & Supplements | Finn',
  description:
    'Trusted nutrition for your trusty companion. Finn formulates premium dog supplements and vitamins that combine trusted research and modern wellness. Shop now.'
}

const IndexPage: React.FC<PageProps> = ({ location }) => {
  const { title, description } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} location={location} />

      <Layout location={location}>
        <Homepage />
      </Layout>
    </>
  )
}

export default IndexPage
